import React, { memo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as styles from './Privacy.module.scss';

function Privacy(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={styles.privacy}>
      <h1 className={styles.privacy__heading}>{t('privacy:first_text')}</h1>
      <div className={styles.privacy__content}>
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:second_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
        >
          {t('privacy:third_text')}
        </p>
        <h1
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_bold,
          )}
        >
          {t('privacy:fourth_text')}
        </h1>
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:fifth_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
        >
          {t('privacy:sixth_text')}
        </p>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
        >
          {t('privacy:seventh_text')}
        </p>
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:eighth_text') }}
        />
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:ninth_text') }}
        />
        <h1
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_bold,
          )}
        >
          {t('privacy:tenth_text')}
        </h1>
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:eleventh_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:twelve_text') }}
        />
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
        >
          {t('privacy:thirteenth_text')}
        </p>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:fourteenth_text') }}
        />
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:fifteenth_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:sixteenth_text') }}
        />
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:seventeenth_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:eighteenth_text') }}
        />
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:nineteenth_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:twentieth_text') }}
        />
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:twentyFirst_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:twentySecond_text') }}
        />
        <h1
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_bold,
          )}
        >
          {t('privacy:twentyThird_text')}
        </h1>
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:twentyFourth_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:twentyFifth_text') }}
        />
        <h1
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_bold,
          )}
        >
          {t('privacy:twentySixth_text')}
        </h1>
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:twentySeventh_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:twentyEighth_text') }}
        />
        <h1
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_bold,
          )}
        >
          {t('privacy:twentyNinth_text')}
        </h1>
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:thirty_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:thirtyOne_text') }}
        />
        <h1
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_bold,
          )}
        >
          {t('privacy:thirtySecond_text')}
        </h1>
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:thirtyThird_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:thirtyFourth_text') }}
        />
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:thirtyFifth_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:thirtySixth_text') }}
        />
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:thirtySeventh_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:thirtyEighth_text') }}
        />
        <h6
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_middle,
          )}
        >
          {t('privacy:thirtyNinth_text')}
        </h6>
        <p
          className={classNames(
            styles.privacy__content__text,
            styles.privacy__content__text_small,
          )}
          dangerouslySetInnerHTML={{ __html: t('privacy:fortieth_text') }}
        />
      </div>
    </div>
  );
}

export default memo(Privacy);
